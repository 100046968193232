import _ from 'lodash'
import {
  isInputBorderBottomGS,
  isSkinWithStepBackground,
  isWithBottomRdOnly,
  isSkinWithGradientBackground,
} from './get-subscribers-utils'
import { GSExtraData, GSLayout, GSExtraStyle, FntColorProps } from './get-subscribers-types'
import { customFntSpan, fntP } from '../form-style-service'

export enum GSLabels {
  Title = 'Title',
  Subtitle = 'Subtitle',
  ThankYouTitle = 'ThankYouTitle',
  ThankYouSubtitle = 'ThankYouSubtitle',
  FieldLabel = 'FieldLabel',
}

const defaultsByStyle = [
  {
    container: {
      brw: 3,
    },
    input: {
      'alpha-brd': '1',
      brw: `0 0 1px 0`,
      brwh: `0 0 1px 0`,
      brwe: `0 0 2px 0`,
      brwf: `0 0 1px 0`,
    },
    button: {
      brw: '2px',
    },
  },
  {
    container: {
      brw: 3,
      rd: 0,
    },
    input: {
      'alpha-brd': '1',
      brw: `1px`,
      brwe: `2px`,
    },
    button: {
      brw: '2px',
    },
  },
  {
    container: {},
    input: {
      'alpha-brd': '1',
      brw: `0 0 1px 0`,
      brwh: `0 0 1px 0`,
      brwe: `0 0 2px 0`,
      brwf: `0 0 1px 0`,
    },
    button: {
      brw: '0px',
      rd: '20px',
    },
  },
  {
    container: {
      bg: 'rgba(232,230,230,1)',
    },
    input: {
      'alpha-brd': '1',
      brw: `1px`,
      brwe: `2px`,
    },
    button: {
      brw: '2px',
      rd: '10px',
    },
  },
  {
    container: {},
    input: {
      'alpha-brd': '1',
      brw: `1px`,
      brwe: `2px`,
    },
    button: {
      brw: '2px',
    },
  },
]

const fontAndColor = (style, fontKey, colorKey, extraStyle: GSExtraStyle) => {
  const font = JSON.parse(style[fontKey] || '{}') // TODO add default
  const color = style[colorKey]
  return { color, font, ...extraStyle } as FntColorProps
}

export const getTitleStyles = (label: GSLabels, extraData: GSExtraData) => {
  switch (label) {
    case GSLabels.Title:
      return fontAndColor(
        extraData.componentStyle,
        'param_font_titleFont',
        'param_color_titleColor',
        extraData?.desktopLayouts.title.container.extraStyles,
      )
    case GSLabels.Subtitle:
      return fontAndColor(
        extraData.componentStyle,
        'param_font_subtitleFont',
        'param_color_subtitleColor',
        extraData?.desktopLayouts.subTitle.container.extraStyles,
      )
    case GSLabels.ThankYouTitle:
      return fontAndColor(
        extraData.componentStyle,
        'param_font_titleFont',
        'param_color_titleColor',
        extraData?.desktopLayouts.successTitle.extraStyles,
      )
    case GSLabels.ThankYouSubtitle:
      return fontAndColor(
        extraData.componentStyle,
        'param_font_subtitleFont',
        'param_color_titleColor',
        extraData?.desktopLayouts.successSubtitle.extraStyles,
      )
    case GSLabels.FieldLabel:
      return fontAndColor(
        extraData.componentStyle,
        'param_font_labelFont',
        'param_color_labelTextColor',
        extraData?.desktopLayouts.email.label.extraStyles,
      )
    default:
      return {
        color: null,
        font: null,
        lineHeight: null,
        align: null,
      } as FntColorProps
  }
}

export const containerBGColor = (settings: GetSubscribersSettings, extraData: GSExtraData) => {
  const style = extraData.componentStyle
  const isGradient = isSkinWithGradientBackground(settings)
  const bgShadeColor = isGradient && extraData.desktopLayouts.appContainer.extraStyles.bgShadeColor
  const bgShadeAlpha = isGradient && extraData.desktopLayouts.appContainer.extraStyles.bgShadeAlpha
  return {
    bg: bgShadeColor || style.param_color_backgroundColor,
    aBg: bgShadeAlpha || style['alpha-param_color_backgroundColor'],
  }
}

export const convertColorToBackgroundMediaSchema = (color: string) => {
  if (color.startsWith('rgba(')) {
    return color.substring(5, color.length - 1)
  }
  return color
}

export const containerStyle = (settings: GetSubscribersSettings, extraData: GSExtraData) => {
  const style_num = settings.style.appStyle
  const style = extraData.componentStyle
  const withBorder = isSkinWithStepBackground(settings)
  const withBottomRd = isWithBottomRdOnly(settings)
  const brd = style.param_color_bordersColor
  const brw = withBorder ? style.param_number_bordersSlider : 0
  const rd = style.param_number_cornersSlider

  return {
    properties: _.merge({}, defaultsByStyle[style_num].container, {
      'alpha-brd': '1',
      'boxShadowToggleOn-shd': 'false',
      brd,
      brw,
      rd: withBottomRd ? `0 0 ${rd}px ${rd}px` : `${rd}px`,
      shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
    }),
    propertiesSource: {
      'alpha-brd': 'value',
      'boxShadowToggleOn-shd': 'value',
      brd: 'value',
      brw: 'value',
      rd: 'value',
      bg: 'value',
      shd: 'value',
    },
    groups: {},
  }
}

interface StyleAttributes {
  editorKey: string
  family: string
  fontStyleParam: boolean
  preset: string
  size: number
  style: {
    bold: false
    italic: false
    underline: false
  }
}
const createFnt = (fntStyle, gsLayout: GSLayout) => {
  const { extraStyles } = gsLayout
  if (fntStyle) {
    const styleAttributes: StyleAttributes = JSON.parse(fntStyle)
    if (styleAttributes.preset !== 'Custom' && styleAttributes.editorKey) {
      return styleAttributes.editorKey
    }
    return `${extraStyles.fontStyle} ${extraStyles.fontVariant} ${extraStyles.fontWeight} ${
      extraStyles.fontSize
    }/${extraStyles.lineHeight} ${styleAttributes.family.replace(' ', '+')}`
  } else {
    return `${extraStyles.fontStyle} ${extraStyles.fontVariant} ${extraStyles.fontWeight} ${
      extraStyles.fontSize
    }/${extraStyles.lineHeight} ${extraStyles.fontFamily.replace(' ', '+')}`
  }
}

export const inputStyle = (app_style, extraData: GSExtraData) => {
  const style = extraData.componentStyle
  const rawBrw = style.param_number_linesSlider
  console.log('rawBrw', rawBrw)
  const intBrw = rawBrw && _.ceil(parseFloat(rawBrw))
  console.log('intBrw', intBrw)
  const brw = intBrw && `${intBrw}`
  console.log('brw', brw)
  const aBrd = style['alpha-param_color_linesColor']
  const brd = style.param_color_linesColor
  const bg = style.param_color_FieldBackgroundColor
  const alphaBackground = style['alpha-param_color_FieldBackgroundColor']
  const txt = style.param_color_fieldTextColor
  const atxt = style['alpha-param_color_fieldTextColor']
  const txtlbl = style.param_color_labelTextColor
  const atxtlbl = style['alpha-param_color_labelTextColor']

  const fnt = createFnt(style.param_font_textFont, extraData.desktopLayouts.email.input)
  const fntlbl = createFnt(style.param_font_labelFont, extraData.desktopLayouts.email.label)

  const aBg = isInputBorderBottomGS(app_style) ? '0' : alphaBackground
  return {
    properties: _.merge({}, defaultsByStyle[app_style].input, {
      'alpha-brd': aBrd,
      'alpha-bg': aBg,
      'alpha-bgh': aBg,
      'alpha-bge': aBg,
      'alpha-bgf': aBg,
      'alpha-txt': atxt,
      'alpha-txtlbl': atxtlbl,
      brw: intBrw && (isInputBorderBottomGS(app_style) ? `0 0 ${intBrw}px 0` : brw),
      brwe: brw && (isInputBorderBottomGS(app_style) ? `0 0 ${intBrw + 1}px 0` : `${intBrw + 1}`),
      brwf: intBrw && (isInputBorderBottomGS(app_style) ? `0 0 ${intBrw}px 0` : brw),
      brwh: intBrw && (isInputBorderBottomGS(app_style) ? `0 0 ${intBrw}px 0` : brw),
      brd,
      brde: '#ec4d4d',
      brdf: brd,
      brdh: brd,
      bg,
      bge: bg,
      bgf: bg,
      bgh: bg,
      fnt,
      fntlbl,
      txtlbl,
      txt,
      txt2: txt,
      txtlblrq: txtlbl,
    }),
    propertiesSource: {
      'alpha-brd': 'value',
      'alpha-bg': 'value',
      'alpha-bgh': 'value',
      'alpha-bgf': 'value',
      'alpha-bge': 'value',
      'alpha-txt': 'value',
      'alpha-txtlbl': 'value',
      bg: 'value',
      bge: 'value',
      bgf: 'value',
      bgh: 'value',
      brd: 'value',
      brde: 'value',
      brdf: 'value',
      brdh: 'value',
      brw: 'value',
      brwe: 'value',
      brwf: 'value',
      brwh: 'value',
      fnt: 'value',
      fntlbl: 'value',
      txtlbl: 'value',
      txt: 'value',
      txt2: 'value',
      txtlblrq: 'value',
    },
    groups: {},
  }
}
export const agreeStyle = (app_style, extraData: GSExtraData) => {
  const style = extraData.componentStyle
  const rawBrw = style.param_number_linesSlider
  const intBrw = rawBrw && _.ceil(parseFloat(rawBrw))
  const brw = intBrw && `${intBrw}`
  const aBrd = style['alpha-param_color_linesColor']
  const brd = style.param_color_linesColor
  const bg = style.param_color_FieldBackgroundColor
  const aBg = style['alpha-param_color_FieldBackgroundColor']
  const bgc = style.param_color_fieldTextColor
  const txt = style.param_color_consentStatementColor

  const fnt = createFnt(
    style.param_font_consentStatementFont,
    extraData.desktopLayouts.consent.text,
  )

  return {
    properties: _.merge({}, defaultsByStyle[app_style].input, {
      'alpha-brd': aBrd,
      'alpha-brdf': aBrd,
      'alpha-bg': aBg,
      'alpha-bgf': aBg,
      'alpha-bge': aBg,
      'alpha-bgh': aBg,
      brw,
      brwe: intBrw ? `${intBrw + 1}` : undefined,
      brwf: brw,
      brwh: brw,
      brd,
      brde: '#ec4d4d',
      brdf: brd,
      brdh: brd,
      fnt,
      bg,
      bgf: bg,
      bge: bg,
      bgh: bg,
      rd: 0,
      bgc,
      bgch: bgc,
      bgcd: bgc,
      txt,
    }),
    propertiesSource: {
      'alpha-brd': 'value',
      'alpha-brdf': 'value',
      'alpha-bg': 'value',
      'alpha-bgf': 'value',
      'alpha-bge': 'value',
      'alpha-bgh': 'value',
      brw: 'value',
      brwe: 'value',
      brwf: 'value',
      brwh: 'value',
      fnt: 'value',
      brd: 'value',
      brde: 'value',
      brdf: 'value',
      brdh: 'value',
      bge: 'value',
      bgh: 'value',
      bgf: 'value',
      rd: 'value',
      bgc: 'value',
      bgcd: 'value',
      bgch: 'value',
      txt: 'value',
    },
    groups: {},
  }
}

export const buttonStyle = (app_style, extraData: GSExtraData) => {
  const style = extraData.componentStyle
  const aBrd = style['alpha-param_color_buttonBorderColor']
  const aBg = style['alpha-param_color_buttonColor']
  const aTxt = style['alpha-param_color_buttonTextColor']
  const brd = style.param_color_buttonBorderColor
  const bg = style.param_color_buttonColor
  const txt = style.param_color_buttonTextColor || '#ffffff'

  const brw_raw = style.param_number_buttonBorderWidth
  const lines_w = style.param_number_linesSlider
  const brw = parseFloat(brw_raw) >= 0 ? brw_raw : lines_w

  const fnt = createFnt(style.param_font_buttonFont, extraData.desktopLayouts.button)

  return {
    properties: _.merge({}, defaultsByStyle[app_style].button, {
      'alpha-bg': aBg,
      'alpha-bgh': aBg,
      'alpha-brd': aBrd,
      'alpha-brdh': aBrd,
      'alpha-txt': aTxt,
      'alpha-txth': aTxt,
      bg,
      bgh: bg,
      // 'boxShadowToggleOn-shd': 'false',
      brd,
      brdh: brd,
      brw,
      // fnt: fnt.editorKey || 'font_8',
      // shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
      fnt,
      txt,
      txth: txt,
    }),
    propertiesSource: {
      'alpha-bg': 'value',
      'alpha-bgh': 'value',
      'alpha-brd': 'value',
      'alpha-brdh': 'value',
      'alpha-txt': 'value',
      'alpha-txth': 'value',
      bg: 'value',
      bgh: 'value',
      brd: 'value',
      brdh: 'value',
      brw: 'value',
      fnt: 'value',
      txt: 'value',
      txth: 'value',
    },
    groups: {},
  }
}

export const design0BoxStyle = (extraData: GSExtraData) => {
  const style = extraData.componentStyle
  const aBg = style['alpha-param_color_titleBackgroundColor']
  const bg = style.param_color_titleBackgroundColor
  const rd = extraData.componentStyle.param_number_cornersSlider

  return {
    properties: {
      'alpha-bg': aBg,
      bg,
      rd: `${rd}px ${rd}px 0 0`,
    },
    propertiesSource: {
      'alpha-bg': 'value',
      bg: 'value',
    },
  }
}

export const designBottomRadiusCardStyle = (extraData: GSExtraData) => {
  const rd = extraData.componentStyle.param_number_cornersSlider
  return {
    properties: {
      rd: `0 0 ${rd}px ${rd}px`,
    },
    propertiesSource: {
      rd: 'value',
    },
  }
}

export const designBoxStyle = (settings: GetSubscribersSettings, extraData: GSExtraData) => {
  const extensionList = [
    design0BoxStyle,
    () => {},
    design2CardStyle,
    designBottomRadiusCardStyle,
    designBottomRadiusCardStyle,
  ]
  const { aBg, bg } = containerBGColor(settings, extraData)
  const extension = extensionList[settings.style.appStyle](extraData, settings)
  return _.merge(
    {},
    {
      properties: {
        'alpha-bg': aBg,
        bg,
      },
      propertiesSource: {
        'alpha-bg': 'value',
        bg: 'value',
      },
    },
    extension,
  )
}

export const convertStampStyle = (stamp, extraData: GSExtraData) => {
  const bg = extraData.componentStyle.param_color_backgroundColor
  if (!bg || ['white', '#FFFFFF', 'color_11'].includes(bg)) {
    stamp.design.shapeStyle.opacity = 1
  }
  return stamp
}

export const design2CardStyle = (extraData, settings) => {
  const { aBg } = containerBGColor(settings, extraData)
  const floatABG = parseFloat(aBg)
  const simulatedABG = `${2 * floatABG - floatABG * floatABG}`
  return {
    properties: {
      'alpha-bg': simulatedABG,
      'alpha-brd': '1',
      'boxShadowToggleOn-shd': 'true',
      brd: 'color_15',
      brw: 0,
      rd: '0px',
      shd: '0.00px 0.00px 5px 1px rgba(0,0,0,1)',
    },
    propertiesSource: {
      'alpha-bg': 'value',
      'alpha-brd': 'value',
      brd: 'value',
      brw: 'value',
      'boxShadowToggleOn-shd': 'value',
      rd: 'value',
      shd: 'value',
    },
    groups: {},
  }
}

export const getTextAlignmentFromBodyAlignment = (number: 0 | 1) =>
  number === 0 ? 'left' : 'right'
export const getTextAlignmentFromHeaderAlignment = (align) => {
  switch (align) {
    case 0:
      return 'left'
    case 1:
      return 'center'
    case 2:
      return 'right'
    default:
      return 'left'
  }
}

export const textParagraph = (content, textAlignment: 'left' | 'right' | 'center') => {
  return fntP({
    content,
    textAlignment,
  })
}

export const textSpan = (text, titleStyles: FntColorProps) => {
  const {
    color,
    font,
    fontWeight,
    fontStyle,
    fontDecorate,
    fontVariant,
    fontSize,
    align,
    lineHeight,
  } = titleStyles
  const fontAttribute =
    font.preset === 'Custom' || !font.editorKey
      ? { family: font.family.replace(' ', '+') }
      : { fontTheme: font.editorKey }

  return customFntSpan({
    ...fontAttribute,
    color,
    text,
    weight: fontWeight,
    style: fontStyle,
    variant: fontVariant,
    decorate: fontDecorate,
    size: fontSize,
    lineHeight,
    align,
  })
}

export const getBorderSize = (style: any) =>
  _.ceil(parseFloat(style.param_number_linesSlider || '1'))

export const defaultGSStyle = {
  'alpha-param_color_FieldBackgroundColor': '1',
  'alpha-param_color_backgroundColor': '1',
  'alpha-param_color_bordersColor': '1',
  'alpha-param_color_buttonBorderColor': '1',
  'alpha-param_color_buttonColor': '1',
  'alpha-param_color_buttonTextColor': '1',
  'alpha-param_color_consentStatementColor': '1',
  'alpha-param_color_errorMessageColor': '1',
  'alpha-param_color_fieldTextColor': '1',
  'alpha-param_color_iconTextColor': '1',
  'alpha-param_color_labelTextColor': '1',
  'alpha-param_color_linesColor': '1',
  'alpha-param_color_subtitleColor': '1',
  'alpha-param_color_titleBackgroundColor': '1',
  'alpha-param_color_titleColor': '1',
  param_color_FieldBackgroundColor: 'color_12',
  param_color_backgroundColor: 'color_11',
  param_color_bordersColor: 'color_14',
  param_color_buttonBorderColor: 'color_18',
  param_color_buttonColor: 'color_18',
  param_color_buttonTextColor: 'color_11',
  param_color_consentStatementColor: 'color_20',
  param_color_errorMessageColor: 'rgba(255,0,0,1)',
  param_color_fieldTextColor: 'color_20',
  param_color_iconTextColor: 'color_14',
  param_color_labelTextColor: 'color_19',
  param_color_linesColor: 'color_14',
  param_color_subtitleColor: 'color_19',
  param_color_titleBackgroundColor: 'color_12',
  param_color_titleColor: 'color_19',
  param_font_buttonFont:
    '{"family":"proxima-n-w01-reg","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"15","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
  param_font_consentStatementFont:
    '{"family":"proxima-n-w01-reg","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"15","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
  param_font_labelFont:
    '{"family":"proxima-n-w01-reg","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"15","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
  param_font_subtitleFont:
    '{"family":"proxima-n-w01-reg","displayName":"Paragraph 1","style":{"bold":false,"italic":false,"underline":false},"size":"17","preset":"Body-L","editorKey":"font_7","fontStyleParam":true}',
  param_font_textFont:
    '{"family":"proxima-n-w01-reg","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"15","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
  param_font_titleFont:
    '{"family":"proxima-n-w01-reg","displayName":"Paragraph 1","style":{"bold":false,"italic":false,"underline":false},"size":"17","preset":"Body-L","editorKey":"font_7","fontStyleParam":true}',
}
